export function getInitials(string: string): string {
	const names = string.split(' ')
	let initials = ''

	if (names[0]) {
		initials = names[0].substring(0, 1).toUpperCase()
	}

	if (names.length > 1 && names[names.length - 1]) {
		initials += names[names.length - 1]?.substring(0, 1).toUpperCase()
	}

	return initials
}

/**
 * Titleizes a string by capitalizing the first letter of each word and making other letters lowercase.
 * Handles empty strings gracefully by returning an empty string if the input is empty.
 * @param {string} str - The string to be titleized.
 * @returns {string} - The titleized string or an empty string if input is empty.
 */
export function titleize(str: string): string {
	if (str.length === 0) {
		return ''
	}
	return str.replace(
		/\w\S*/g,
		(txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
	)
}
